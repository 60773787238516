import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import {
    Contract,
    ContractDocumentType,
    EmploymentType,
    JobTitle,
    UploadedContractDocumentDetails,
    UploadedDocumentDictionary,
    WageType,
} from '../../../@types';
import { Link as RouterLink } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getEnumKeyByValue, useAuth, useSnackBar } from '../../../utils';
import { SecuredLayout } from '../../Layout/SecuredLayout';
import { CloudUploadOutlined, FileDownloadOutlined, LibraryAddOutlined } from '@mui/icons-material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useJobTitles } from '../../../hooks';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import { Role } from '../../../@types/auth';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

interface LocationStateType {
    employeeName?: string;
}

const defaultEmptyJobTitle: JobTitle = {
    name: '',
    value: '',
};

const ContractDetailsPage = () => {
    const today = new Date();
    const { user } = useAuth();
    const { employeeId, contractId } = useParams();
    const { snackBar, showError, showResponseError, showSuccess } = useSnackBar();
    const { jobTitles } = useJobTitles();
    const location = useLocation();
    const state = location.state as LocationStateType;
    const navigate = useNavigate();
    const isModify = location.pathname.includes('modify');

    const [formReadOnly, setFormReadOnly] = useState<boolean>(!!contractId);
    const [from, setFrom] = useState<Date>(today);
    const [to, setTo] = useState<Date | null>(new Date(today.getTime() + 60 * 60 * 24 * 365 * 1000));
    const [fixEnded, setFixEnded] = useState<boolean>(false);
    const [employmentType, setEmploymentType] = useState<EmploymentType>(EmploymentType.PERMANENT);
    const [dailyWorkHours, setDailyWorkHours] = useState<number>(8);
    const [wageType, setWageType] = useState<WageType>(WageType.MONTHLY);
    const [wage, setWage] = useState<number | undefined>(0);
    const [jobTitle, setJobTitle] = useState<JobTitle>(defaultEmptyJobTitle);
    const [documents, setDocuments] = useState<UploadedDocumentDictionary>();
    const [viewId, setViewId] = useState(Math.random());
    const [isModifiedContract, setModifiedContract] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!state || !state.employeeName) {
            navigate(`/employee/${employeeId}/`);
        }
    }, []);

    useEffect(() => {
        setViewId(Math.random());
    }, [navigate]);

    useEffect(() => {
        const getContractDetails = async () => {
            setLoading(true);
            const response = await fetch(`${API_ENDPOINT}/contract/details/${contractId}`, {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            const data: Contract = await response.json();
            setLoading(false);
            if (!data) {
                return;
            }
            setJobTitle(data.jobTitle || defaultEmptyJobTitle);
            setFormReadOnly(true);
            setFrom(new Date(data.from));
            setTo(data.to ? new Date(data.to) : null);
            setFixEnded(data.to !== null);
            setEmploymentType(EmploymentType[data.employmentType]);
            setDailyWorkHours(data.dailyWorkHours);
            setWageType(WageType[data.wageType]);
            setWage(data.wage);
            setModifiedContract(data.origin !== null);

            const uploadedDocuments = data.documents.reduce((acc, curr) => {
                const { link, acceptOn } = curr;
                return {
                    ...acc,
                    [curr.documentType]: {
                        link,
                        acceptOn,
                    },
                };
            }, {});
            setDocuments(uploadedDocuments as UploadedDocumentDictionary);
        };

        if (contractId && !loading) {
            getContractDetails();
        }
    }, [contractId, user?.accessToken, viewId]);

    const handleSave = async () => {
        if (!user) {
            return;
        }

        const response = await fetch(`${API_ENDPOINT}/contract/`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.accessToken}`,
            },
            body: JSON.stringify({
                employeeUuid: employeeId,
                from,
                jobTitle: jobTitle?.name,
                to: fixEnded ? to : null,
                employmentType: getEnumKeyByValue(EmploymentType, employmentType),
                wageType: getEnumKeyByValue(WageType, wageType),
                wage,
                dailyWorkHours,
                fixEnded,
                originContractUUID: isModify ? contractId : null,
            }),
        });

        if (response.status !== 201) {
            await showResponseError(response);
            return;
        }

        navigate(`/employee/${employeeId}`);
    };

    const handleUpload = async (files: FileList | null, contractType: string) => {
        if (!files || !contractType || !user) {
            return;
        }
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('contractId', contractId || '');
        formData.append('documentType', contractType);

        const response = await fetch(`${API_ENDPOINT}/document/contract`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                Authorization: `Bearer ${user.accessToken}`,
            },
            body: formData,
        });

        if (response.ok) {
            showSuccess(
                <Typography paragraph p={0} m={0}>
                    Sikeres feltöltés
                </Typography>
            );
            setViewId(Math.random());
            return;
        }
        showError(
            <Typography paragraph p={0} m={0}>
                Sikertelen feltöltés
            </Typography>
        );
    };

    return (
        <SecuredLayout>
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Állomány
                        </Link>
                        <Link
                            component={RouterLink}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color='inherit'
                            to={'/employee/' + employeeId}>
                            <PermIdentityOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            {state?.employeeName || ''}
                        </Link>
                        <Link
                            component={RouterLink}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color='inherit'
                            onClick={e => {
                                e.preventDefault();
                                navigate(`/employee/${employeeId}/contract`, {
                                    state,
                                });
                            }}
                            to={`/employee/${employeeId}/contract`}>
                            <ArticleIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Szerződések
                        </Link>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} color='inherit'>
                            {contractId === undefined ? (
                                <>
                                    <PostAddOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    Új szerződéstervezet
                                </>
                            ) : (
                                <>
                                    <AssignmentOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                                    Szerződés{isModifiedContract ? 'módosítás' : ''} részletei
                                </>
                            )}
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Box component='form'>
                <Grid container>
                    {snackBar()}
                    <Grid xs={12} lg={7}>
                        <Card variant={'elevation'} sx={{ px: 1, py: 3, m: 2 }}>
                            <Grid container px={2} spacing={2}>
                                <Typography variant={'h4'}>
                                    {contractId ? `Szerződés${isModifiedContract ? 'módosítás' : ''} részletei` : 'Új szerződéstervezet'}
                                </Typography>
                            </Grid>

                            <Grid container pt={2} px={2} spacing={4}>
                                <Grid xs={12} sm={8}>
                                    <FormControl required>
                                        <FormLabel>Foglalkoztatás formája</FormLabel>
                                        <RadioGroup
                                            row
                                            value={employmentType}
                                            onChange={(e, value) => {
                                                e.persist();
                                                const type = value as EmploymentType;
                                                if (type === EmploymentType.SIMPLE_EMPLOYMENT) {
                                                    setWageType(WageType.DAILY);
                                                    setFixEnded(false);
                                                }
                                                if (type === EmploymentType.CONTRACTOR) {
                                                    setWageType(WageType.MONTHLY);
                                                }
                                                setEmploymentType(type);
                                            }}>
                                            {Object.keys(EmploymentType).map(key => (
                                                <FormControlLabel
                                                    key={key}
                                                    control={
                                                        <Radio
                                                            disabled={formReadOnly}
                                                            readOnly={formReadOnly}
                                                            value={EmploymentType[key as keyof typeof EmploymentType]}
                                                        />
                                                    }
                                                    label={EmploymentType[key as keyof typeof EmploymentType]}
                                                    value={EmploymentType[key as keyof typeof EmploymentType]}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} sm={4}>
                                    <Autocomplete
                                        options={[defaultEmptyJobTitle, ...jobTitles]}
                                        getOptionLabel={jobTitle => jobTitle.name}
                                        disableClearable
                                        disabled={formReadOnly}
                                        isOptionEqualToValue={(option: JobTitle, value: JobTitle) => option.name === value.name}
                                        onChange={(e, value) => setJobTitle(value)}
                                        value={jobTitle}
                                        renderInput={params => <TextField {...params} variant='standard' label='Munkakör' />}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container p={2} spacing={4}>
                                <Grid xs={12} sm={6} md={3} lg={4}>
                                    <DesktopDatePicker
                                        label='Szerződés kezdete'
                                        format='yyyy.MM.dd.'
                                        value={from}
                                        disabled={formReadOnly && !isModify}
                                        onChange={date => setFrom(date || new Date())}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid xs={12} sm={4} lg={4} alignSelf={'flex-end'} display={'flex'}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                sx={{ ml: 0 }}
                                                labelPlacement={'start'}
                                                control={
                                                    <Switch
                                                        checked={fixEnded}
                                                        disabled={formReadOnly}
                                                        onChange={e => {
                                                            const { checked } = e.target;
                                                            setFixEnded(checked);
                                                        }}
                                                    />
                                                }
                                                label={'Határozott idejű szerződés?'}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} sm={6} md={3} lg={4}>
                                    {fixEnded && (
                                        <DesktopDatePicker
                                            label='Szerződés vége'
                                            format='yyyy.MM.dd.'
                                            minDate={new Date(from.getTime() + 60 * 60 * 24 * 1000)}
                                            disabled={formReadOnly}
                                            value={to}
                                            onChange={date => setTo(date)}
                                            slotProps={{
                                                textField: {
                                                    variant: 'standard',
                                                    fullWidth: true,
                                                },
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container p={2} spacing={2}>
                                <Grid xs={12} sm={6}>
                                    <FormControl required sx={{ mt: -1 }}>
                                        <FormLabel>Munkabér módja</FormLabel>
                                        <RadioGroup
                                            row
                                            value={wageType}
                                            onChange={(e, value) => {
                                                e.persist();
                                                setWageType(value as WageType);
                                            }}>
                                            {Object.keys(WageType).map((type, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    control={<Radio disabled={formReadOnly} readOnly={formReadOnly} />}
                                                    label={WageType[type as keyof typeof WageType]}
                                                    value={WageType[type as keyof typeof WageType]}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} sm={3}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Munkabér összeg'}
                                        disabled={!isModify && formReadOnly}
                                        value={wage}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='start'> Ft</InputAdornment>,
                                        }}
                                        onChange={e => {
                                            const wage = Number(e.target.value);
                                            if (!isNaN(wage)) {
                                                setWage(wage);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>Napi óraszám</InputLabel>
                                        <Select
                                            variant='standard'
                                            disabled={!isModify && formReadOnly}
                                            onChange={e => setDailyWorkHours(e.target.value as number)}
                                            readOnly={!isModify && formReadOnly}
                                            value={dailyWorkHours}>
                                            {Array(8)
                                                .fill(0)
                                                .map((el, i) => (
                                                    <MenuItem value={i + 1} key={i}>
                                                        {i + 1} óra
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {contractId && (
                        <Grid xs={12} lg={5}>
                            <Card variant={'elevation'} sx={{ backgroundColor: '#eee', px: 1, py: 3, m: 2 }}>
                                <Grid container px={2} pb={2} pt={0}>
                                    <Typography variant={'h4'}>Aláírandó dokumentumok</Typography>
                                </Grid>
                                <Grid container>
                                    <Grid xs={12}>
                                        <List sx={{ width: '100%' }}>
                                            {Object.keys(ContractDocumentType)
                                                .filter(docType => (isModifiedContract ? docType === 'SZERZODESMODOSITAS' : docType !== 'SZERZODESMODOSITAS'))
                                                .map((value, index) => {
                                                    const documentTypeKey = value as ContractDocumentType;
                                                    let documentDetails: UploadedContractDocumentDetails | undefined;
                                                    if (documents && documents[documentTypeKey]) {
                                                        documentDetails = documents[documentTypeKey] as UploadedContractDocumentDetails;
                                                    }
                                                    return (
                                                        <ListItem sx={{ m: 0, p: 0 }} key={index}>
                                                            <Link
                                                                sx={{
                                                                    textDecoration: 'none',
                                                                    color: 'black',
                                                                    width: '100%',
                                                                }}
                                                                target={'_blank'}
                                                                href={`${API_ENDPOINT}/contract/${contractId}/${value.toLowerCase()}`}>
                                                                <ListItemButton sx={{ width: '100%' }}>
                                                                    <ListItemText
                                                                        primary={`${ContractDocumentType[value as keyof typeof ContractDocumentType]}`}
                                                                    />
                                                                </ListItemButton>
                                                            </Link>

                                                            {documentDetails ? (
                                                                <Link
                                                                    target={'_blank'}
                                                                    href={`${API_ENDPOINT}${documentDetails.link}?token=${user?.accessToken}`}>
                                                                    <IconButton>
                                                                        <FileDownloadOutlined />
                                                                    </IconButton>
                                                                </Link>
                                                            ) : (
                                                                <>
                                                                    <IconButton component={'label'}>
                                                                        <CloudUploadOutlined />
                                                                        <input onChange={event => handleUpload(event.target.files, value)} hidden type='file' />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </ListItem>
                                                    );
                                                })}
                                        </List>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    )}
                    <Grid xs={12}>
                        <Grid container justifyContent={'end'} spacing={2} m={2} py={2}>
                            {contractId && !isModify && (user?.hasRole(Role.BR_HR) || user?.hasRole(Role.BR_HR_HELYETTES)) && !to && (
                                <Button
                                    sx={{ mx: 1, borderRadius: '50px' }}
                                    variant={'contained'}
                                    color={'warning'}
                                    onClick={() =>
                                        navigate(`/employee/${employeeId}/contract/${contractId}/modify`, {
                                            state: {
                                                employeeName: state.employeeName,
                                            },
                                        })
                                    }
                                    endIcon={<EditIcon />}>
                                    módosítás
                                </Button>
                            )}
                            {(!contractId || isModify) && (
                                <Button
                                    sx={{ mx: 1, borderRadius: '50px' }}
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={handleSave}
                                    endIcon={<LibraryAddOutlined />}>
                                    mentés
                                </Button>
                            )}
                            <Button
                                sx={{ mx: 1, borderRadius: '50px' }}
                                variant={'contained'}
                                color={'info'}
                                onClick={() => navigate(-1)}
                                startIcon={<ArrowBackIosNewTwoToneIcon />}>
                                vissza
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </SecuredLayout>
    );
};

export { ContractDetailsPage };
