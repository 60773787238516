import React, {useCallback, useState} from "react";
import {Alert, AlertColor, List, ListItem, ListItemText, Snackbar, Typography} from "@mui/material";

const useSnackBar = () => {
    const [message, setMessage] = useState<JSX.Element>()
    const [open, setOpen] = useState<boolean>(false)
    const [severity, setSeverity] = useState<AlertColor>("success");

    const showError = useCallback((msg: JSX.Element) => {
        setSeverity("error")
        setOpen(true)
        setMessage(msg)
    }, [open])

    const showResponseError = async (response: Response) => {
        const body = await response.json();
        const errorMessage = <>
            <Typography paragraph m={0} p={0}>{body.status || response.statusText}</Typography>
            {body.errors && (<List>
                {Object.keys(body.errors).map((errorKey: string, i) =>
                    <ListItem sx={{p: 0, m: 0}} key={i}>
                        <ListItemText primary={body.errors[errorKey]}/>
                    </ListItem>
                )}
            </List>)}
        </>
        showError(errorMessage);
    }

    const showSuccess = (msg: JSX.Element) => {
        setSeverity("success");
        setOpen(true)
        setMessage(msg);
    }

    const snackBar = () => {
        return (
            <Snackbar
                autoHideDuration={6000}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{vertical: "top", horizontal: "right"}}>
                <Alert onClose={() => setOpen(false)} severity={severity} sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
        )
    }

    return {
        snackBar,
        setMessage,
        setOpen,
        setSeverity,
        showError,
        showSuccess,
        showResponseError,
        open
    }
}

export {useSnackBar};