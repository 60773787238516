import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { EmployeeListPage } from './Employee/EmployeeList';
import { NotFoundPage } from './Common';
import { ContractListPage } from './Contract';
import { BrowserRouter } from 'react-router-dom';
import { Box, createTheme, styled, ThemeProvider } from '@mui/material';
import { EmployeeDetailsPage } from './Employee/EmployeeDetails/EmployeeDetailsPage';
import { NotificationDetailsPage } from './Notification';
import { ProvideAuth } from '../hooks';
import { ProvideJobTitles } from '../hooks/useJobTitles';
import { DocumentListPage } from './Document';
import { LoginPaper } from './Login';
import { ProvideCompanyDoctors } from '../hooks/useCompanyDoctors';
import { ContractDetailsPage } from './Contract/ContractDetails/ContractDetailsPage';
import { EFOHistoryPage } from './EFOHistory';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { EmployeeListSearchParamsProvider } from '../hooks/useEmployeeListSearchParams';
import ExpiringHrDocuments from './ExpiringHrDocuments/ExpiringHrDocuments';

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY || 'not known';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const StyledApp = styled(Box)`
    background-image: url('/images/brb_logo_vertical_white.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#42C4AB',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StyledApp>
                <BrowserRouter>
                    <ProvideAuth>
                        <ProvideJobTitles>
                            <ProvideCompanyDoctors>
                                <EmployeeListSearchParamsProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
                                        <Routes>
                                            <Route path='/' element={<Navigate to={'/employee?jobtitle=autóbuszvezető&status=active'} />} />
                                            <Route path='/employee/' element={<EmployeeListPage />} />
                                            <Route path='/employee/create' element={<EmployeeDetailsPage />} />
                                            <Route path='/expiringdocuments' element={<ExpiringHrDocuments />} />
                                            <Route path='/employee/:employeeId' element={<EmployeeDetailsPage />} />
                                            <Route path='/employee/:employeeId/contract' element={<ContractListPage />} />
                                            <Route path='/employee/:employeeId/contract/create' element={<ContractDetailsPage />} />
                                            <Route path='/employee/:employeeId/contract/:contractId' element={<ContractDetailsPage />} />
                                            <Route path='/employee/:employeeId/contract/:contractId/modify' element={<ContractDetailsPage />} />

                                            <Route path='/employee/:employeeId/notification' element={<NotificationDetailsPage />} />
                                            <Route path='/employee/:employeeId/document' element={<DocumentListPage />} />
                                            <Route path='/efo' element={<EFOHistoryPage />} />
                                            <Route path='/login' element={<LoginPaper />} />
                                            <Route path='*' element={<NotFoundPage />} />
                                        </Routes>
                                    </LocalizationProvider>
                                </EmployeeListSearchParamsProvider>
                            </ProvideCompanyDoctors>
                        </ProvideJobTitles>
                    </ProvideAuth>
                </BrowserRouter>
            </StyledApp>
        </ThemeProvider>
    );
}

export default App;
