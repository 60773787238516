import React from 'react';

interface Props {
    children: React.ReactNode;

}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(error: Error): State {
      return { hasError: true };
    }
  
    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      console.error('Uncaught error:', error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return <h2>Something went wrong...</h2>;
      }
  
      return this.props.children;
    }
  }

  export default ErrorBoundary;