import { SecuredLayout } from '../Layout/SecuredLayout';
import { Grid, Stack, Typography, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth, useSnackBar } from '../../hooks';
import { DataGridPro, GridColDef, GridRowsProp, getGridStringOperators, huHU } from '@mui/x-data-grid-pro';
import { StyledMainPaper } from '../Layout/styles';
import { CardType, ExpiringDriverCertificateItem, ExpiringDocumentsResponse, ExpiringDriverCertificateGridItem } from '../../@types/employee';
import { getCardTypeName } from '../../utils/getCardTypeName';
import ErrorBoundary from '../../hooks/errorBoundary';
import { ExpandedRowsProvider } from '../../hooks/useExpandedRowsContext';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function ExpiringHrDocuments() {
    const { user } = useAuth();
    const { showError, showResponseError } = useSnackBar();
    const [expiringDriverCertificateItems, setExpiringDriverCertificateItems] = useState<ExpiringDriverCertificateItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<GridRowsProp>([]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            groupable: true,
            width: 550,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Név</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'documentType',
            headerName: 'Lejáró dokumentumok',
            width: 550,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Lejáró dokumentumok</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
        {
            field: 'expirationDate',
            headerName: 'Lejárat',
            width: 550,
            sortable: true,
            headerAlign: 'left',
            align: 'left',
            renderHeader: () => <strong>Lejárat</strong>,
            filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
        },
    ];

    const fetchGetExpiringDocuments = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_ENDPOINT}/employee/expiringdocuments`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const data: ExpiringDocumentsResponse = await response.json();
                setExpiringDriverCertificateItems(data.content);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGetExpiringDocuments();
    }, []);

    const createRows = (expiringCardItem: ExpiringDriverCertificateItem[]): GridRowsProp => {
        let rows: GridRowsProp = [];
        expiringCardItem.forEach(item => {
            const cardData: ExpiringDriverCertificateGridItem = {
                id: item.uuid + '//' + item.type,
                name: item.lastName + ' ' + item.firstName,
                documentType: getCardTypeName(item.type),
                expirationDate: item.validTo ? item.validTo : ' Nincs megadva',
            };
            rows = rows.concat(cardData);
        });
        return rows;
    };

    useEffect(() => {
        setRows(createRows(expiringDriverCertificateItems));
    }, [expiringDriverCertificateItems]);

    return (
        <SecuredLayout>
            <Grid container>
                <StyledMainPaper>
                    {expiringDriverCertificateItems.length > 0 ? (
                        <Stack sx={{ height: 'calc(100vh - 90px)' }}>
                            <ExpandedRowsProvider>
                                <ErrorBoundary>
                                    <DataGridPro
                                        density={'compact'}
                                        columns={columns}
                                        rows={rows}
                                        rowHeight={40}
                                        localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
                                        unstable_headerFilters
                                        pagination
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 100 } },
                                            sorting: {
                                                sortModel: [
                                                    { field: 'expirationDate', sort: 'asc' },
                                                    { field: 'name', sort: 'asc' },
                                                ],
                                            },
                                        }}
                                        pageSizeOptions={[100, 200, 300]}
                                    />
                                </ErrorBoundary>
                            </ExpandedRowsProvider>
                        </Stack>
                    ) : (
                        <Grid
                            container
                            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 90px)' }}>
                            <Grid item>
                                <Typography variant='h6'>
                                    {loading ? (
                                        <Stack direction='row' spacing={2} alignItems='center'>
                                            <CircularProgress
                                                sx={{
                                                    fontSize: '60px',
                                                }}
                                            />
                                            <Typography variant='h6'>Adatok betöltése...</Typography>
                                        </Stack>
                                    ) : (
                                        'Nincs megjeleníthető adat'
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </StyledMainPaper>
            </Grid>
        </SecuredLayout>
    );
}
