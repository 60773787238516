export const enum InputField {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    CITY = 'city',
    MOTHERS_NAME = 'mothersName',
    TAX_NUMBER = 'taxNumber',
    HEALTH_CARE_NUMBER = 'healthCareNumber',
    ID_CARD_NUMBER = 'idCardNumber',
    ADDRESS_CARD_NUMBER = 'addressCardNumber',
    DRIVING_LICENCE_NUMBER = 'drivingLicenceNumber',
    DRIVING_QUALIFICATION_LICENCE_NUMBER = 'drivingQualificationLicenceNumber',
    TACHOGRAP_CARD_NUMBER = 'tachographCardNumber',
    PHONE_NUMBER = 'phoneNumber',
    EMAIL = 'email',
    PERMANENT_ADDRESS = 'permanentAddress',
    BANK_ACCOUNT = 'bankAccount',
    IBUTTON = 'IBUTTON',
}

export type FieldValidationPattern = {
    field: InputField;
    pattern: RegExp;
    message?: String;
};

export const validationPatterns: FieldValidationPattern[] = [
    {
        field: InputField.FIRST_NAME,
        pattern: /^[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+[-\s]?[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/,
    },
    {
        field: InputField.LAST_NAME,
        pattern: /^[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+\s?[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/,
    },
    {
        field: InputField.CITY,
        pattern: /^[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ-]+$/,
    },
    {
        field: InputField.MOTHERS_NAME,
        pattern: /^[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+[-\s]?[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+[\s]?[\wáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/,
        message: 'Hibás formátum!',
    },
    {
        field: InputField.TAX_NUMBER,
        pattern: /^\d{10}$/,
        message: 'Hibás formátum (10 számjegy)',
    },
    {
        field: InputField.HEALTH_CARE_NUMBER,
        pattern: /^\d{3}[-\s]?\d{3}[-\s]?\d{3}$/,
    },
    {
        field: InputField.ID_CARD_NUMBER,
        pattern: /^\d{6}\w{2}$/,
        message: 'Hibás formátum (minta: 111222AA)',
    },
    {
        field: InputField.ADDRESS_CARD_NUMBER,
        pattern: /^\d{6}\w{2}$/,
        message: 'Hibás formátum (minta: 111222AA)',
    },
    {
        field: InputField.DRIVING_LICENCE_NUMBER,
        pattern: /^\w{2}\d{6}$/,
        message: 'Hibás formátum (minta: CD111222)',
    },
    {
        field: InputField.DRIVING_QUALIFICATION_LICENCE_NUMBER,
        pattern: /^\w{2}-\d{6}$/,
        message: 'Hibás formátum (minta: AA-123123)',
    },
    {
        field: InputField.TACHOGRAP_CARD_NUMBER,
        pattern: /^\w{3}\d{13}$/,
        message: 'Hibás formátum (minta: HUG2222233333444)',
    },
    {
        field: InputField.PHONE_NUMBER,
        pattern: /^\d{9,14}$/,
        message: 'Hibás formátum (csak számjegyeket tartalmaz)',
    },
    {
        field: InputField.EMAIL,
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
    },
    {
        field: InputField.PERMANENT_ADDRESS,
        pattern: /^[\w\W]{4,}$/,
    },
    {
        field: InputField.BANK_ACCOUNT,
        pattern: /^(\d{8}[\s-]?\d{8}[\s-]?(\d{8,})?)|(\w{2}\d{2}(\s?\d{4}){6})$/,
    },
    {
        field: InputField.IBUTTON,
        pattern: /^([\dA-F]{12})?$/,
    },
];
