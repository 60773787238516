import {InputField, validationPatterns} from "../components/Employee/EmployeeDetails/validation";

const fieldValidator = (value: string, field: InputField, disabled = false) => {
    const fieldValidationPattern = validationPatterns.find(item => item.field === field);
    if (fieldValidationPattern === undefined) {
        return null;
    }
    const {pattern, message} = fieldValidationPattern;

    return {
        error: !pattern.test(value) && !disabled,
        helperText: !pattern.test(value) && !disabled ?  (message || "Hibás kitöltés"): " "
    }
}

export {fieldValidator}