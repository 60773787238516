import { Employee } from '../@types';
import * as XLSX from 'xlsx-js-style';
import FileSaver from 'file-saver';
import { formatDateToYyyyMMddhhmmss, leftPad } from './date-format-handler';
import { EmploymentWeekdaySummery } from '../@types/employee';

const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILE_EXTENSION = '.xlsx';

const BORDERED_CELL_STYLE = {
    border: {
        top: {
            style: 'thin',
            color: {
                rgb: '#000000',
            },
        },
        right: {
            style: 'thin',
            color: {
                rgb: '#000000',
            },
        },
        bottom: {
            style: 'thin',
            color: {
                rgb: '#000000',
            },
        },
        left: {
            style: 'thin',
            color: {
                rgb: '#000000',
            },
        },
    },
};

const getEmployeeName = (employee: Employee) => {
    return employee.legalName || employee.lastName + ' ' + employee.firstName;
};

const generateDayByDayList = (records: EmploymentWeekdaySummery[], from: Date, to: Date): any[] => {
    const currentDate = new Date(formatDateToYyyyMMddhhmmss(from));
    const dateList = [];
    let sumAmount = 0;

    while (currentDate.getMonth() === to.getMonth()) {
        const dateString = currentDate.toLocaleDateString('hu-HU');
        const record = records.find(r => r.date === dateString && r.notificationType === 'U' && r.company === 'PANNON_BUSZ_RENT');

        dateList.push([
            {
                v: dateString,
                t: 's',
                s: { font: { sz: 8 }, alignment: { vertical: 'center', horizontal: 'center' }, ...BORDERED_CELL_STYLE },
            },
            {
                v: record !== undefined ? 8 : '',
                t: 's',
                s: { font: { sz: 8 }, alignment: { vertical: 'center', horizontal: 'center' }, ...BORDERED_CELL_STYLE },
            },
            {
                v: record?.wage || '',
                t: 's',
                s: { font: { sz: 8 }, alignment: { vertical: 'center', horizontal: 'center' }, ...BORDERED_CELL_STYLE },
            },
            {
                v: record === undefined ? '' : 2700,
                t: 's',
                s: { font: { sz: 8 }, alignment: { vertical: 'center', horizontal: 'center' }, ...BORDERED_CELL_STYLE },
            },
            {
                v: record === undefined ? '' : 'átutalás / átvét',
                t: 's',
                s: { font: { sz: 8 }, alignment: { vertical: 'center', horizontal: 'center' }, ...BORDERED_CELL_STYLE },
            },
            {
                v: record === undefined ? '' : 'változó / autóbuszvezető',
                t: 's',
                s: { font: { sz: 8 }, alignment: { vertical: 'center', horizontal: 'center' }, ...BORDERED_CELL_STYLE },
            },
        ]);

        currentDate.setDate(currentDate.getDate() + 1)
        if (record) {
            sumAmount += record.wage || 0;
        }
    }

    dateList.push([
        {
            v: 'Személyi alapbér a munkaviszony teljes idejére',
            t: 's',
            s: {
                font: { bold: true, sz: 8 },
                alignment: { vertical: 'center', horizontal: 'left' },
                ...BORDERED_CELL_STYLE,
            },
        },
        {
            v: '',
            t: 's',
            s: {
                ...BORDERED_CELL_STYLE,
            },
        },
        {
            v: '',
            t: 's',
            s: {
                ...BORDERED_CELL_STYLE,
            },
        },
        {
            v: '',
            t: 's',
            s: {
                ...BORDERED_CELL_STYLE,
            },
        },
        {
            v: sumAmount,
            t: 's',
            s: {
                font: { bold: true, sz: 8 },
                alignment: { vertical: 'center', horizontal: 'center' },
                ...BORDERED_CELL_STYLE,
            },
        },
    ]);

    return dateList;
};

const generateAttendanceSheet = (employee: Employee, records: EmploymentWeekdaySummery[], from: Date, to: Date) => {
    const workBook = XLSX.utils.book_new();

    const dailyAttendance = generateDayByDayList(records, from, to);

    const worksheet = XLSX.utils.aoa_to_sheet([
        [
            {
                v: 'Munkáltató adatai:',
                t: 's',
                s: {
                    font: { bold: true, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {},
            {},
            {
                v: 'Egyszerűsített munka jellege',
                t: 's',
                s: {
                    font: { bold: true, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Egyszerűsített munka közterhe',
                t: 's',
                s: {
                    font: { bold: true, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
        [
            {
                v: 'Munkáltató megnevezése:',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: 'Pannon Busz-Rent Kft',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: 'mg-i idénymunka',
                t: 's',
                s: {
                    font: { bold: false, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '500 Ft',
                t: 's',
                s: {
                    font: { bold: false, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
        [
            {
                v: 'Székhelye:',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: '7761 Kozármisleny, Nárcisz utca 33.',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: 'turisztikai idénymunka',
                t: 's',
                s: {
                    font: { bold: false, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '500 Ft',
                t: 's',
                s: {
                    font: { bold: false, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
        [
            {
                v: 'Adószáma:',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: '14055223-2-02',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: 'alkalmi munka',
                t: 's',
                s: {
                    font: { bold: false, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '1 000 Ft',
                t: 's',
                s: {
                    font: { bold: false, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
        [],
        [
            {
                v: 'Munkavállaló adatai:',
                t: 's',
                s: {
                    font: { bold: true, sz: 10 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
        ],
        [
            {
                v: 'Családi és utóneve:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: getEmployeeName(employee),
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: 'TAJ szám:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {
                v: employee.healthCareNumber,
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {
                v: 'Születési családi és utóneve:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: getEmployeeName(employee),
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: 'Adóazonosító jel:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {
                v: employee.taxNumber,
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {
                v: 'Születési hely:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: employee.placeOfBirth,
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: 'MNYP tagság:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {
                v: '',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {
                v: 'Születés ideje:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: employee.dateOfBirth,
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: 'MNYP belépés dátuma:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {
                v: '',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {
                v: 'Anyja leánykori neve:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: employee.mothersName,
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: '(záradékolt belépési nyilatkozat másolatának leadása szükséges)',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
        ],
        [
            {
                v: 'Lakóhelye:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {
                v: employee.permanentAddress,
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [],
        [
            {
                v: 'A munkavállaló munkavédelmi oktatásban részesült.',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                },
            },
        ],
        [
            {
                v: 'Munkaviszony kezdete:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {},
            {},
            {
                v: '',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {
                v: 'Munkaviszony megszűnésének tagja: ***',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {},
            {},
            {
                v: '',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {
                v: 'Munkakör:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {},
            {},
            {
                v: employee.jobTitle,
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {
                v: 'Egyszerűsített foglalkozás NAV felé történő jelentésének ideje, módja:',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                },
            },
            {},
            {},
            {},
            {
                v: 'EFO bejelentő',
                t: 's',
                s: {
                    font: { bold: false, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [],
        [
            {
                v: 'munkanapok',
                t: 's',
                s: {
                    font: { bold: true, sz: 8 },
                    alignment: { vertical: 'top', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'ledolgozott órák',
                t: 's',
                s: {
                    font: { bold: true, sz: 8 },
                    alignment: { vertical: 'top', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Napi kifizetett kereset 337/2010.(XII.27.) korm.rend.szerint',
                t: 's',
                s: {
                    font: { bold: true, sz: 8 },
                    alignment: { vertical: 'top', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Közteher',
                t: 's',
                s: {
                    font: { bold: true, sz: 8 },
                    alignment: { vertical: 'top', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'dolgozó aláírása / munkabér átvételének igazolása',
                t: 's',
                s: {
                    font: { bold: true, sz: 8 },
                    alignment: { vertical: 'top', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Munkavégzés helye (változó munkahely esetén) / Munkakör (változó munkakör esetén)',
                t: 's',
                s: {
                    font: { bold: true, sz: 8 },
                    alignment: { vertical: 'top', horizontal: 'center', wrapText: true },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
        ...dailyAttendance,
        [],
        [],
        [
            {
                v:
                    'Kelt: ' +
                    new Date(records.find(r => r.company === 'PANNON_BUSZ_RENT' && r.notificationType === 'U')?.date || new Date()).toLocaleString('hu-HU', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }),
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    border: {
                        bottom: {
                            style: 'thick',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: '',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'right' },
                    border: {
                        bottom: {
                            style: 'thick',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {
                v: '',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'right' },
                    border: {
                        bottom: {
                            style: 'thick',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
            {},
            {
                v: '',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'right' },
                    border: {
                        bottom: {
                            style: 'thick',
                            color: {
                                rgb: '#000000',
                            },
                        },
                    },
                },
            },
        ],
        [
            {},
            {},
            {
                v: 'munkáltató aláírása',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                },
            },
            {},
            {},
            {
                v: 'munkavállaló aláírása',
                t: 's',
                s: {
                    font: { italic: true, sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                },
            },
        ],
        [],
        [
            {},
            {
                v: '*Az 508/2023 (XI.20) kormányrendeletnek megfelelően',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                },
            },
        ],
        [
            {},
            {
                v: '2024 évben',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Havi bér',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Heti bér',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Napi bér',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: 'Óra bér',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
        [
            {},
            {
                v: 'Minimális bér',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '266 800 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '61 340 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '12 270 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '1 534 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
        [
            {},
            {
                v: 'Garantált bérminimum',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'left' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '326 000 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '74 950 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '14 990 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
            {
                v: '1 874 Ft',
                t: 's',
                s: {
                    font: { sz: 8 },
                    alignment: { vertical: 'center', horizontal: 'center' },
                    ...BORDERED_CELL_STYLE,
                },
            },
        ],
    ]);

    worksheet['!cols'] = [{ wch: 11 }, { wch: 13 }, { wch: 14 }, { wch: 11 }, { wch: 20 }, { wch: 27 }];

    if (worksheet['!rows'] === undefined) {
        worksheet['!rows'] = [];
    }

    worksheet['!rows'][19] = { hpt: 35 };

    if (!worksheet['!merges']) worksheet['!merges'] = [];
    worksheet['!merges'].push(XLSX.utils.decode_range('A1:B1'));
    worksheet['!merges'].push(XLSX.utils.decode_range('A2:B2'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C2:D2'));
    worksheet['!merges'].push(XLSX.utils.decode_range('A3:B3'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C3:D3'));
    worksheet['!merges'].push(XLSX.utils.decode_range('A4:B4'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C4:D4'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A6:B6'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A7:B7'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C7:D7'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A8:B8'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C8:D8'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A9:B9'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C9:D9'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A10:B10'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C10:D10'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A11:B11'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C11:D11'));
    worksheet['!merges'].push(XLSX.utils.decode_range('E11:F11'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A12:B12'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C12:E12'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A14:F14'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A15:D15'));
    worksheet['!merges'].push(XLSX.utils.decode_range('E15:F15'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A16:D16'));
    worksheet['!merges'].push(XLSX.utils.decode_range('E16:F16'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A17:B17'));
    worksheet['!merges'].push(XLSX.utils.decode_range('C17:D17'));

    worksheet['!merges'].push(XLSX.utils.decode_range('A18:D18'));

    worksheet['!merges'].push(XLSX.utils.decode_range(`A${20 + dailyAttendance.length}:D${20 + dailyAttendance.length}`));

    worksheet['!merges'].push(XLSX.utils.decode_range(`C${24 + dailyAttendance.length}:D${24 + dailyAttendance.length}`));

    worksheet['!merges'].push(XLSX.utils.decode_range(`B${26 + dailyAttendance.length}:F${26 + dailyAttendance.length}`));

    XLSX.utils.book_append_sheet(workBook, worksheet, 'Jelenléti ív');

    const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: FILE_TYPE });
    FileSaver.saveAs(
        data,
        `${from.getFullYear()}${leftPad(from.getMonth() + 1)}${leftPad(from.getDate())} - ${getEmployeeName(employee)} - jelenléti ív${FILE_EXTENSION}`
    );
};

export { generateAttendanceSheet };
