export const getCardTypeName = (cardType: String) => {
    switch (cardType) {
        case 'ID_CARD':
            return 'Személyi igazolvány';
        case 'ADDRESS_CARD':
            return 'Lakcímkártya';
        case 'DRIVING_LICENCE_CARD':
            return 'Jogosítvány';
        case 'DRIVER_QUALIFICATION_LICENCE_CARD':
            return 'Gki kártya érv.';
        case 'TACHOGRAPH_CARD':
            return 'Tachográf kártya';
        case 'MEDICAL_FITNESS_CARD':
            return 'E.ü. érv.';
        default:
            return 'Ismeretlen kártya';
    }
};