import { NotifyEmployeeModal } from '../../Employee/EmployeeList/NotifyEmployeeModal';
import React, { useEffect, useState } from 'react';
import { SecuredLayout } from '../../Layout/SecuredLayout';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Breadcrumbs, Button, Card, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { useLocation, useNavigate } from 'react-router';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { useAuth, useSnackBar } from '../../../hooks';
import { EFOStatistics, EmployerCompany } from '../../../@types';
import { getEnumKeyByValue, getErrorMessage } from '../../../utils';
import { Employee, EmploymentWeekdaySummery, ListResponse, NotificationType } from '../../../@types/employee';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import { NotificationStatistics } from '../NotificationStatistics/NotificationStatistics';
import { generateAttendanceSheet } from '../../../utils/generate-attendance-sheet';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getLastDayOfMonth, leftPad } from '../../../utils/date-format-handler';

const EFO_API_ENDPOINT = process.env.REACT_APP_EFO_API_ENDPOINT;

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: '#',
        width: 20,
    },
    {
        field: 'date',
        headerName: 'Munkanap',
        width: 110,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'wage',
        headerName: 'Munkabér',
        width: 100,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params: GridValueGetterParams) => (params.row.wage?.toLocaleString('hu-HU') || '-') + ' Ft',
    },
    {
        field: 'company',
        headerName: 'Cég',
        width: 160,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params: GridValueGetterParams) => EmployerCompany[params.row.company as keyof typeof EmployerCompany],
    },
    {
        field: 'createdBy',
        headerName: 'Bejelentő',
        type: 'number',
        width: 110,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'createdAt',
        headerName: 'Bejelentés időpont',
        width: 180,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'confirmedAt',
        headerName: 'Visszaigazolás időpontja',
        type: 'string',
        width: 180,
        sortable: false,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'right',
        headerAlign: 'right',
    },
    {
        field: 'notificationType',
        headerName: 'Típusa',
        width: 80,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params: GridValueGetterParams) => (params.row.notificationType.toUpperCase() === 'U' ? 'Bejelentés' : 'Kijelentés'),
    },
    {
        field: 'uuid',
        headerName: ' ',
        width: 110,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        renderCell: (params: GridRenderCellParams) => {
            const isInPast = new Date(params.row.date).getTime() < new Date().getTime();
            if (!params.row.isCancellable() || isInPast) {
                return null;
            }
            return params.row.notificationType.toUpperCase() === 'U' ? (
                <Button
                    size={'small'}
                    sx={{ mx: 1, borderRadius: 50 }}
                    color={'error'}
                    variant={'contained'}
                    onClick={() => params.row.handleUnregisterClick()}>
                    törlés
                </Button>
            ) : null;
        },
    },
];

interface LocationStateType {
    employeeId: string;
    employeeName: string;
    taxNumber: string;
    healthCareNumber: string;
    employee: Employee;
}

const NotificationDetailsPage = () => {
    const today = new Date();

    const location = useLocation();
    const state = location.state as LocationStateType;
    const { employeeId, employeeName, taxNumber, healthCareNumber, employee } = state;
    console.log(employee);
    const navigate = useNavigate();

    const { user } = useAuth();
    const { showError } = useSnackBar();
    const [records, setRecords] = useState<EmploymentWeekdaySummery[]>([]);
    const [from, setFrom] = useState<Date>(new Date(`${today.getFullYear()}-${today.getMonth() + 1}-1`));
    const [to, setTo] = useState<Date>(new Date(getLastDayOfMonth(from)));
    const [notifyModalOpen, setNotifyModalOpen] = useState<boolean>(false);
    const [isUnregister, setIsUnregister] = useState<boolean>(false);
    const [date, setDate] = useState<number>(today.getTime());
    const [company, setCompany] = useState(getEnumKeyByValue(EmployerCompany, EmployerCompany.PANNON_BUSZ_RENT));
    const [consecutiveDays, setConsecutiveDays] = useState<number>(0);
    const [wage, setWage] = useState<number>(0);

    useEffect(() => {
        if (!taxNumber) {
            return;
        }

        handleGetNotifications();
    }, [taxNumber, from, to]);

    const handleGetNotifications = async () => {
        try {
            const params = new URLSearchParams();
            params.append('from', `${from.getFullYear()}-${leftPad(from.getMonth() + 1)}-${leftPad(from.getDate())}`);
            params.append('to', `${to.getFullYear()}-${leftPad(to.getMonth() + 1)}-${leftPad(to.getDate())}`);

            const response = await fetch(`${EFO_API_ENDPOINT}/notification/${taxNumber}?${params.toString()}`, {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (!response.ok) {
                showError(
                    <Typography paragraph p={0} m={0}>
                        {getErrorMessage(response)}
                    </Typography>
                );
                return;
            }

            const data: ListResponse<EmploymentWeekdaySummery> & EFOStatistics = await response.json();
            setRecords(data.content.filter(record => record.company !== 'PANNON_BUSZ_RENT' || (record.company === 'PANNON_BUSZ_RENT' && !!record.confirmedAt)));
        } catch (e) {
            setRecords([]);
        }
    };

    const changeSelectedNotifyIntervalWithMonth = async (numberOfMonth: number) => {
        let year = from.getFullYear();
        let month = from.getMonth();

        if (month + numberOfMonth < 0) {
            const newFrom = new Date(Date.parse(`${year - 1}-12-01`));
            setFrom(newFrom);
            setTo(new Date(getLastDayOfMonth(newFrom)));
            return;
        }

        if (month + numberOfMonth > 11) {
            const newFrom = new Date(Date.parse(`${year + 1}-01-01`));
            setFrom(newFrom);
            setTo(new Date(getLastDayOfMonth(newFrom)));
            return;
        }

        const newFrom = new Date(Date.parse(`${year}-${month + numberOfMonth + 1}-01`));
        setFrom(newFrom);
        setTo(new Date(getLastDayOfMonth(newFrom)));
        return;
    };

    return (
        <SecuredLayout>
            <NotifyEmployeeModal
                open={notifyModalOpen}
                onClose={() => setNotifyModalOpen(false)}
                isUnregister={isUnregister}
                consecutiveDays={consecutiveDays}
                date={date}
                company={company as keyof typeof EmployerCompany}
                employee={{
                    legalName: employeeName,
                    taxNumber,
                    healthCareNumber,
                }}
                externalWage={wage}
            />
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <GroupOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Állomány
                        </Link>
                        <Link
                            component={RouterLink}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color='inherit'
                            to={`/employee/${employeeId}/`}>
                            <PermIdentityOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            {employeeName}
                        </Link>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} color='inherit'>
                            <BusinessOutlinedIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            Egyszerűsített foglalkoztatás bejelentések
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Box component='form'>
                <Grid container>
                    <Grid xs={12} lg={8}>
                        <Card variant={'elevation'} sx={{ px: 1, py: 3, m: 2 }}>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Typography variant={'h4'}>Alkalmi bejelentések</Typography>
                            </Grid>
                            <Grid container alignItems={'center'}>
                                <Grid xs={12} md={'auto'} p={2}>
                                    <Button
                                        sx={{ m: 0, p: 1, minWidth: 'fit-content', borderRadius: 50 }}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={() => changeSelectedNotifyIntervalWithMonth(-1)}>
                                        <ChevronLeftIcon sx={{ m: 0 }} />
                                    </Button>
                                </Grid>
                                <Grid xs={12} md={'auto'} p={2}>
                                    <DesktopDatePicker
                                        label='Kezdő dátum'
                                        format='yyyy.MM.dd.'
                                        value={from}
                                        onChange={date => setFrom(date || new Date())}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} md={'auto'} p={2}>
                                    <DesktopDatePicker
                                        label='Záró dátum'
                                        format='yyyy.MM.dd.'
                                        value={to}
                                        onChange={date => setTo(date || new Date())}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} md={'auto'} p={2}>
                                    <Button
                                        sx={{ m: 0, p: 1, minWidth: 'fit-content', borderRadius: 50 }}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={() => changeSelectedNotifyIntervalWithMonth(1)}>
                                        <ChevronRightIcon sx={{ m: 0 }} />
                                    </Button>
                                </Grid>
                                <Grid xs={12} md={'auto'} p={0}>
                                    <Button
                                        sx={{ mx: 1, borderRadius: 50 }}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={handleGetNotifications}
                                        endIcon={<SearchIcon />}>
                                        keresés
                                    </Button>
                                </Grid>
                                <Grid xs={12} md={'auto'} p={0}>
                                    <Button
                                        sx={{ mx: 1, borderRadius: 50 }}
                                        variant={'contained'}
                                        color={'info'}
                                        onClick={() => generateAttendanceSheet(employee, records, from, to)}
                                        endIcon={<ListAltIcon />}>
                                        Jelenléti ív
                                    </Button>
                                </Grid>

                                <Stack sx={{ minHeight: 500, height: 'fit-content', width: '100%' }}>
                                    <DataGrid
                                        density={'compact'}
                                        columns={columns}
                                        rows={records.map((r: EmploymentWeekdaySummery, index) => {
                                            const getCountOfConsecutiveDays = () => {
                                                const currentIndex = records.findIndex(rec => rec.id === r.id);
                                                let counter = 0;
                                                for (let i = currentIndex + 1; i < currentIndex + 5 && i < records.length; i++) {
                                                    const item = records[i];

                                                    if (item.company !== r.company) {
                                                        break;
                                                    }
                                                    if (item.notificationType === getEnumKeyByValue(NotificationType, NotificationType.T)) {
                                                        break;
                                                    }
                                                    const baseWorkdayTime = new Date(r.date).getTime();
                                                    const nextWorkdayTime = new Date(item.date).getTime();
                                                    const oneDayTime = 24 * 60 * 60 * 1000;
                                                    if (baseWorkdayTime + (counter + 1) * oneDayTime !== nextWorkdayTime) {
                                                        break;
                                                    }
                                                    counter++;
                                                }
                                                return counter;
                                            };

                                            return {
                                                ...r,
                                                id: index + 1,
                                                isCancellable: () => {
                                                    if (r.notificationType !== getEnumKeyByValue(NotificationType, NotificationType.U)) {
                                                        return false;
                                                    }

                                                    if (index === records.length - 1) {
                                                        return true;
                                                    }

                                                    const nextItem = records[index + 1];

                                                    if (r.date !== nextItem.date) {
                                                        return true;
                                                    }

                                                    return nextItem.date === getEnumKeyByValue(NotificationType, NotificationType.U);
                                                },
                                                handleUnregisterClick: () => {
                                                    const workday = new Date(r.date);
                                                    const days = getCountOfConsecutiveDays();
                                                    setCompany(r.company);
                                                    setConsecutiveDays(days);
                                                    setDate(workday.getTime());
                                                    setIsUnregister(true);
                                                    setWage(r.wage);
                                                    setNotifyModalOpen(true);
                                                },
                                            };
                                        })}
                                        pageSize={30}
                                        rowsPerPageOptions={[30]}
                                    />
                                </Stack>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid xs={12} lg={4}>
                        <Card variant={'elevation'} sx={{ backgroundColor: '#eee', px: 1, py: 3, m: 2 }}>
                            <Grid container px={3} pb={2} spacing={2}>
                                <Typography variant={'h4'}>Összesítés</Typography>
                            </Grid>
                            <NotificationStatistics taxNumber={taxNumber} date={today.toDateString()} />
                        </Card>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'end'} spacing={1} mx={1} pt={3} pb={2}>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        color={'info'}
                        onClick={() => navigate(-1)}
                        startIcon={<ArrowBackIosNewTwoToneIcon />}>
                        vissza
                    </Button>
                    <Button
                        sx={{ mx: 1, borderRadius: 50 }}
                        variant={'contained'}
                        color={'warning'}
                        onClick={() => {
                            setDate(today.getTime());
                            setWage(0);
                            setIsUnregister(false);
                            setNotifyModalOpen(true);
                        }}
                        startIcon={<BusinessOutlinedIcon />}>
                        Alkalmi bejelentés
                    </Button>
                </Grid>
            </Box>
        </SecuredLayout>
    );
};

export { NotificationDetailsPage };
