import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { ExpiracyWarningItem, WarningNotificationType } from '../../../@types/employee';
import { DesktopDateTimePicker } from '@mui/x-date-pickers-pro';
import SendIcon from '@mui/icons-material/Send';
import { useAuth, useSnackBar } from '../../../hooks';
import { useParams } from 'react-router';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { getEnumKeyByValue } from '../../../utils';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const CardStyle = styled(Card)`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 1000px;
    max-width: 90%;
    background-color: white;
    box-shadow: 2px 2px;
    padding-bottom: 14px;

    @media (max-width: 900px) {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0%, 0%);
        width: 100%;
    }
`;

const WarningModal: React.FC = () => {
    const { user } = useAuth();
    const { employeeId } = useParams();
    const [open, setOpen] = useState<boolean>(false);
    const [sentAtValue, setSentAtValue] = useState<Date | null>(new Date());
    const [warningTypeValue, setWarningTypeValue] = useState<keyof typeof WarningNotificationType>(
        getEnumKeyByValue(WarningNotificationType, WarningNotificationType.PHONE) as keyof typeof WarningNotificationType
    );
    const [notificationSubjectValue, setNotificationSubjectValue] = useState<string>('');
    const { showResponseError, showError, showSuccess, snackBar } = useSnackBar();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<ExpiracyWarningItem>>([]);
    const [validated, setValidated] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            setValidated(false);
            setSentAtValue(new Date());
            setWarningTypeValue(getEnumKeyByValue(WarningNotificationType, WarningNotificationType.PHONE) as keyof typeof WarningNotificationType);
            setNotificationSubjectValue('');
        }
    }, [open]);

    const postWarningData = async () => {
        setValidated(true);
        if (notificationSubjectValue === '') {
            return;
        }

        try {
            const response = await fetch(`${API_ENDPOINT}/expiracy-warning/`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify({
                    type: warningTypeValue,
                    employeeUuid: employeeId,
                    content: notificationSubjectValue,
                    validTo: '2012-10-01T09:45:00.000+02:00',
                    sentAt: sentAtValue,
                }),
            });
            setValidated(false);
            setNotificationSubjectValue('');

            if (response.status === 201) {
                showSuccess(
                    <Typography paragraph m={0} p={0}>
                        Az értesítést rögzítettük
                    </Typography>
                );
                setLoading(false);
                getWarningData();
                return;
            }
            await showResponseError(response);
            setLoading(false);
            setValidated(false);
        } catch (err: any) {
            showError(<Typography>{err.message}</Typography>);
        }
    };

    const getWarningData = async () => {
        try {
            const response = await fetch(`${API_ENDPOINT}/expiracy-warning/?emplyeUuid=${employeeId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (!response.ok) {
                showError(<Typography>`Fetch request failed with status ${response.status}`</Typography>);
            }

            const data: ExpiracyWarningItem[] = await response.json();
            setData(data.sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime()));
        } catch (error: any) {
            showError(<Typography>`Fetch request failed with status ${error.message}`</Typography>);
        }
    };
    useEffect(() => {
        if (open) {
            getWarningData();
        }
    }, [open]);

    return (
        <>
            {snackBar()}
            <Button
                startIcon={<NotificationsActiveIcon />}
                sx={{ mx: 1, borderRadius: 50 }}
                variant={'contained'}
                color={'error'}
                onClick={() => setOpen(true)}>
                lejárati értesítések
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <CardStyle>
                    <CardContent>
                        <Grid container>
                            <Grid xs={12} item>
                                <Typography variant='h5' align='left' gutterBottom>
                                    Lejárati értesítések
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} pt={2} sx={{ alignItems: 'end' }}>
                            <Grid xs={2} item>
                                <InputLabel>
                                    <small>Értesítés típusa</small>
                                </InputLabel>
                                <Select
                                    variant={'standard'}
                                    value={warningTypeValue}
                                    onChange={e => {
                                        setWarningTypeValue(e.target.value as keyof typeof WarningNotificationType);
                                    }}
                                    fullWidth>
                                    {Object.keys(WarningNotificationType).map(type => (
                                        <MenuItem value={type} key={type}>
                                            {WarningNotificationType[type as keyof typeof WarningNotificationType]}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText> </FormHelperText>
                            </Grid>
                            <Grid xs={2} item>
                                <DesktopDateTimePicker
                                    label='Értesítés időpontja'
                                    format='yyyy.MM.dd HH:mm'
                                    value={sentAtValue}
                                    onChange={date => setSentAtValue(date)}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            fullWidth: true,
                                            helperText: ' ',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid xs={8} item>
                                <TextField
                                    variant='standard'
                                    fullWidth
                                    required
                                    value={notificationSubjectValue}
                                    label='Értesítés tárgya'
                                    error={validated && notificationSubjectValue === ''}
                                    helperText={validated && notificationSubjectValue === '' ? 'Nem lehet üres' : ' '}
                                    onChange={e => {
                                        setNotificationSubjectValue(e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container p={0} pt={2} justifyContent={'center'}>
                            {data.length > 0 ? (
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table padding={'normal'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Értesítés típusa</TableCell>
                                                    <TableCell align='right'>Értesítés időpontja</TableCell>
                                                    <TableCell align='right'>Értesítés tárgya</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map(item => (
                                                    <TableRow
                                                        key={item.id}
                                                        sx={{
                                                            '&:last-child td, &:last-child th': { border: 0 },
                                                            lineHeight: 15,
                                                        }}>
                                                        <TableCell width={120} scope='row'>
                                                            {WarningNotificationType[item.type as keyof typeof WarningNotificationType]}
                                                        </TableCell>
                                                        <TableCell width={180} align='right'>
                                                            {new Date(Date.parse(item.sentAt)).toLocaleString('hu-HU')}
                                                        </TableCell>
                                                        <TableCell align='right'>{item.content}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            ) : null}
                        </Grid>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'space-between' }}>
                        <Button
                            sx={{ mx: 1, borderRadius: 50 }}
                            variant={'contained'}
                            color={'info'}
                            onClick={() => setOpen(false)}
                            startIcon={<ArrowBackIosNewTwoToneIcon />}>
                            bezárás
                        </Button>
                        <Button variant='contained' sx={{ mx: 1, borderRadius: 50 }} onClick={() => postWarningData()} endIcon={<SendIcon />}>
                            mentés
                        </Button>
                    </CardActions>
                </CardStyle>
            </Modal>
        </>
    );
};
export default WarningModal;
