import React from "react";
import {Box, styled} from "@mui/material";
import {EmployeeListItem, EmploymentType, StatusType} from "../../../@types/employee";
import {getEnumKeyByValue} from "../../../utils";
import {AppColors} from "../../../style/AppColors";

type Prop = {
    employee: EmployeeListItem
}

const EmployeeStatusRange = styled(Box)<{ from?: string, to?: string }>`
  width: 100%;
  height: 10px;
  margin-bottom: 8px;
  border-radius: 3px;
  background: linear-gradient(90deg, ${props => (props.from || AppColors.darkBlue)} 0%, ${props => props.to || AppColors.green} 100%);
`

const EmployeeStatusBar = ({employee}: Prop) => {
    const today = new Date();
    const medicalFitnessWarningDate = new Date(employee.medicalFitnessValidTo);
    const DAY_IN_MSEC = 24 * 60 * 60 * 1000;
    medicalFitnessWarningDate.setTime(medicalFitnessWarningDate.getTime() - 60 * DAY_IN_MSEC)
    const {employmentType} = employee;

    const isDriverUnableToWork = () => {
        const shouldHaveValidMedicalFitness = employmentType && (EmploymentType[employmentType] === EmploymentType.PERMANENT || EmploymentType[employmentType] === EmploymentType.CONTRACTOR );
        return (shouldHaveValidMedicalFitness && (employee.medicalFitnessValidTo === null || new Date(employee.medicalFitnessValidTo) < today)) ||
        employee.numberOfExpiredCards > 0 || (employee.jobTitle === 'autóbuszvezető' && employee.numberOfExpiredDriverCards > 0);
    }


    const getFromColor = (employee: EmployeeListItem) => {
        if (employee.status !== getEnumKeyByValue(StatusType, StatusType.ACTIVE)) {
            return AppColors.grey;
        }

        if (isDriverUnableToWork()) {
            return AppColors.red;
        }

        if (employee.retired) {
            return AppColors.lightPurple;
        }

        if (employmentType && (EmploymentType[employmentType] === EmploymentType.PERMANENT)) {
            return AppColors.green;
        }

        return AppColors.lightBlue;
    }

    const getToColor = (employee: EmployeeListItem) => {
        if (employee.status !== getEnumKeyByValue(StatusType, StatusType.ACTIVE)) {
            return AppColors.grey;
        }

        const dateOfBirth = new Date(employee.dateOfBirth);
        const retiredWarningDate = new Date(`${dateOfBirth.getFullYear() + 65}-${dateOfBirth.getMonth() + 1}-${dateOfBirth.getDate()}`);

        retiredWarningDate.setTime(retiredWarningDate.getTime() - 60 * DAY_IN_MSEC);

        if (isDriverUnableToWork()) {
            return AppColors.red;
        }

        if (medicalFitnessWarningDate.getTime() < today.getTime() || employee.numberOfExpiringCards > 0) {
            return AppColors.dangerOrange;
        }

        if (!employee.retired && retiredWarningDate < today) {
            return AppColors.darkPurple;
        }

        if (employee.retired) {
            return AppColors.lightPurple;
        }

        if (employmentType && (EmploymentType[employmentType] === EmploymentType.PERMANENT)) {
            return AppColors.green;
        }

        return AppColors.lightBlue;
    }

    return <EmployeeStatusRange
        from={getFromColor(employee)}
        to={getToColor(employee)}/>
}

export {EmployeeStatusBar}
