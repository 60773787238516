export enum EmployeeDocumentType {
    CARD = 'igazolvány',
    PENSIONER_STATEMENT = 'nyugdíjas igazolás',
    ANNUITY_STATEMENT = 'járadék igazolás',
    MEDICAL_CERTIFICATE = 'egészségügyi alkalmassági igazolás',
    ADDITIONAL_LEAVE_STATEMENT = 'pótszabadság nyilatkozat',
    PREVIOUS_EMPLOYMENT_TERMINATION = 'Előző munkahelyi kilépő papírok',
    TAX_ALLOWANCE_DECLARATION = 'Adókedvezmény nyilatkozat',
    SOCIAL_SECURITY_CONTRIBUTION = 'Szochó kedvezmény',
    T1041_FORM = 'T1041-es nyomtatvány',
    OTHER_DOCUMENT = 'egyéb dokumentum',
}

export enum DriverClassification {
    BUS_LOCAL = 'kisbusz régió',
    LARGE_BUS_LOCAL = 'nagybusz régió',
    BUS_FLEX = 'kisbusz flex',
    LARGE_BUS_FLEX = 'nagybusz flex',
    INTERNATIONAL = 'nemzetközi',
}