import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Fade,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { EmployeeNotificationRequiredDetails, EmployerCompany, NotificationType } from '../../../@types';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getEnumKeyByValue } from '../../../utils';
import SendIcon from '@mui/icons-material/Send';
import { useAuth, useSnackBar } from '../../../hooks';
import { useEffect, useState } from 'react';

const EFO_API_ENDPOINT = process.env.REACT_APP_EFO_API_ENDPOINT;

const EFOCard = styled(Card)`
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 400px;
    background-color: white;
    box-shadow: 2px 2px;
    padding-bottom: 14px;
    max-height: 40vh;
    overflow-y: auto;

    @media (max-width: 900px) {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0%, 0%);
        width: 100%;
    }
`;

interface NotifyGroupEmployeeModalProps {
    open: boolean;
    onClose: () => void;
    employees: EmployeeNotificationRequiredDetails[];
}

const NotifyGroupEmployeeModal: React.FC<NotifyGroupEmployeeModalProps> = ({ open, onClose, employees }) => {
    const { user } = useAuth();
    const { snackBar, showSuccess, showError, showResponseError } = useSnackBar();
    const [from, setFrom] = useState<Date | null>(new Date());
    const [numberOfDays, setNumberOfDays] = useState<number>(1);
    const [wage, setWage] = useState<number>(19400);
    const [company, setCompany] = useState(getEnumKeyByValue(EmployerCompany, EmployerCompany.PANNON_BUSZ_RENT));
    const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.U);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setWage(company && company === 'PANNON_BUSZ_RENT' ? 19400 : 15000);
    }, [company]);

    const handleSendNotification = async () => {
        if (loading || employees.length === 0) {
            return;
        }

        if (!from || !user) {
            onClose();
            return;
        }

        try {
            setLoading(true);

            const response = await fetch(`${EFO_API_ENDPOINT}/notification/`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({
                    createdBy: user.name,
                    company,
                    data: employees.map(employee => {
                        const { legalName, taxNumber, healthCareNumber } = employee;
                        return {
                            name: legalName,
                            taxNumber,
                            healthCareNumber,
                            notificationType: getEnumKeyByValue(NotificationType, notificationType),
                            from,
                            numberOfDays,
                            wage,
                        };
                    }),
                }),
            });

            if (response.status === 200) {
                showSuccess(
                    <Typography paragraph m={0} p={0}>
                        A bejelentést rögzítettük
                    </Typography>
                );
                setLoading(false);
                onClose();
                return;
            }
            await showResponseError(response);
            setLoading(false);
        } catch (err: any) {
            showError(<Typography>{err.message}</Typography>);
        }
    };

    return (
        <>
            {snackBar()}
            <Modal open={open} onClose={onClose}>
                <Fade in={open}>
                    <EFOCard>
                        <CardContent sx={{ p: 2 }}>
                            <Grid container pb={4}>
                                <Typography variant='h5'>Csoportos bejelentés</Typography>
                            </Grid>

                            <Grid container justifyContent={'center'}>
                                <Grid item xs={3} sm={6} md={'auto'} pr={1}>
                                    <FormControl margin={'dense'}>
                                        <InputLabel>Típusa</InputLabel>
                                        <Select
                                            sx={{ textAlign: 'center' }}
                                            variant={'standard'}
                                            value={notificationType}
                                            fullWidth={true}
                                            disabled={true}
                                            onChange={e => setNotificationType(e.target.value as NotificationType)}>
                                            <MenuItem value={NotificationType.U}>
                                                <AddCircleOutlineIcon sx={{ fontSize: 14, paddingRight: 1, paddingBottom: 0 }} />
                                                {NotificationType.U}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} sm={3} md={'auto'} py={1} pr={1}>
                                    <DesktopDatePicker
                                        label='Kezdő dátum'
                                        format='yyyy.MM.dd.'
                                        value={from}
                                        minDate={new Date()}
                                        onChange={date => setFrom(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={2} sm={3} md={'auto'} pr={1}>
                                    <FormControl fullWidth margin={'dense'}>
                                        <InputLabel sx={{ textOverflow: 'unset' }}>Nap</InputLabel>
                                        <Select
                                            sx={{ textAlign: 'center' }}
                                            variant={'standard'}
                                            value={numberOfDays}
                                            disabled={true}
                                            onChange={e => setNumberOfDays(e.target.value as number)}>
                                            <MenuItem value={1}>1</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} md={'auto'} my={0} pr={1}>
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        label={'Összeg'}
                                        value={wage}
                                        onChange={e => {
                                            const value = Number(e.target.value);
                                            if (!isNaN(value)) {
                                                setWage(value);
                                            }
                                        }}
                                        sx={{
                                            maxWidth: 80,
                                            pt: 1,
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='start'>Ft</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6} md={'auto'}>
                                    <FormControl margin={'dense'}>
                                        <InputLabel id=''>Cég</InputLabel>
                                        <Select
                                            sx={{ textAlign: 'center', width: 160 }}
                                            variant={'standard'}
                                            value={company}
                                            onChange={e => setCompany(e.target.value as EmployerCompany)}>
                                            {Object.keys(EmployerCompany).map((value, index) => (
                                                <MenuItem value={value} key={index}>
                                                    {EmployerCompany[value as keyof typeof EmployerCompany]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Név</TableCell>
                                            <TableCell>Adóazonosító jel</TableCell>
                                            <TableCell>TAJ szám</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {employees.map((employee, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{employee.legalName}</TableCell>
                                                <TableCell>{employee.taxNumber}</TableCell>
                                                <TableCell>{employee.healthCareNumber}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'space-between' }}>
                            <Button
                                sx={{ mx: 1, borderRadius: 50 }}
                                variant={'contained'}
                                color={'info'}
                                size={'small'}
                                onClick={() => onClose()}
                                startIcon={<ArrowBackIosNewTwoToneIcon />}>
                                Mégsem
                            </Button>
                            <Button
                                sx={{ mx: 1, borderRadius: 50 }}
                                variant={'contained'}
                                size={'small'}
                                color={'warning'}
                                onClick={handleSendNotification}
                                endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <SendIcon />}>
                                Bejelentés beküldése
                            </Button>
                        </CardActions>
                    </EFOCard>
                </Fade>
            </Modal>
        </>
    );
};
export { NotifyGroupEmployeeModal };
