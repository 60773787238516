import styled from '@emotion/styled';
import { Box, Card, CardContent, Checkbox, Link, Stack, Typography } from '@mui/material';
import { EmployeeStatusBar } from './EmployeeStatusBar';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { AppColors } from '../../../style/AppColors';
import { Link as RouterLink } from 'react-router-dom';
import { EmployeeListItem, EmployeeNotificationRequiredDetails, EmploymentType, StatusType } from '../../../@types/employee';
import { getEnumKeyByValue } from '../../../utils';
import { Role, User } from '../../../@types/auth';

const StyledEmployeeCard = styled(Card)`
    border-radius: 5px;
    border: 3px solid transparent;
    padding-bottom: 8px;
    position: relative;
`;

const EmployeeLink = styled(Stack)`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 30px;
    width: 15%;
    text-align: center;

    &:hover {
        cursor: pointer;
        svg {
            fill: white;
            background-color: #42c4ab;
            border: 5px solid #42c4ab;
            border-radius: 50%;
        }
    }
`;

const CornerButton = styled(Box)`
    border-radius: 5px;
    position: absolute;
    right: -1px;
    bottom: 0;
    background-color: #ed6c02;
    cursor: pointer;
    transition: all 150ms ease-in-out;

    &:hover {
        background-color: white;
        transition: all 150ms ease-in-out;
        svg {
            fill: #ed6c02;
        }
    }
`;

type EmployeeCardType = {
    employee: EmployeeListItem;
    onNotifyEmployee: (employee: EmployeeNotificationRequiredDetails) => void;
    onHandleEmailAddressClick: (address: string) => void;
    onEmployeeChange: (employee: EmployeeListItem, checked: boolean) => void;
    checkedEmployees: EmployeeListItem[];
    user: User | undefined;
};

const FONT_SIZE = 14;

const EmployeeCard = ({ employee, onNotifyEmployee, onHandleEmailAddressClick, checkedEmployees, onEmployeeChange, user }: EmployeeCardType) => {
    return (
        <StyledEmployeeCard variant={'elevation'} sx={{ pl: 0.5, m: 1 }}>
            <CardContent sx={{ m: 0, pb: '0!important', pt: 1, px: 0.5 }}>
                <EmployeeStatusBar employee={employee} />
                <Stack flexDirection={'row'}>
                    <Stack>
                        <Typography variant={'h6'} mb={0}>{`${employee.name}`}</Typography>

                        <Typography paragraph fontSize={FONT_SIZE} mb={0.5}>
                            <i>{`${employee.jobTitle || 'ismeretlen munkakör'}`}</i>
                        </Typography>

                        <Typography paragraph fontSize={FONT_SIZE} mb={0.5}>
                            <SmartphoneOutlinedIcon
                                sx={{
                                    ml: 0,
                                    pr: 0.25,
                                    fontSize: 22,
                                    verticalAlign: 'sub',
                                }}
                            />{' '}
                            {`${employee.phoneNumber || 'hiányzó telefonszám'}`}
                        </Typography>
                        <Typography
                            paragraph
                            fontSize={FONT_SIZE}
                            title={'Kattintson a címre a vágólapra másoláshoz'}
                            onClick={e => {
                                e.preventDefault();
                                onHandleEmailAddressClick(employee.email);
                            }}
                            mt={0}
                            mb={0.5}
                            sx={{ cursor: 'pointer' }}>
                            <EmailOutlinedIcon
                                sx={{
                                    ml: 0,
                                    pr: 0.25,
                                    fontSize: 22,
                                    verticalAlign: 'sub',
                                }}
                            />{' '}
                            {`${employee.email || 'hiányzó e-mail cím'}`}
                        </Typography>
                        <Typography paragraph fontSize={FONT_SIZE} mb={0}>
                            <BadgeOutlinedIcon
                                sx={{
                                    pr: 0.25,
                                    fontSize: 22,
                                    verticalAlign: 'sub',
                                }}
                            />
                            {`${employee.idCardNumber || employee.drivingLicenceCardNumber || ' '}`}
                        </Typography>
                    </Stack>
                    <EmployeeLink flexDirection={'column'} justifyContent={'center'}>
                        <Link component={RouterLink} to={`/employee/${employee.uuid}`} sx={{ textDecoration: 'none' }}>
                            <NavigateNextOutlinedIcon sx={{ color: AppColors.grey }} />
                        </Link>
                    </EmployeeLink>
                </Stack>
            </CardContent>
            {employee.employmentType === getEnumKeyByValue(EmploymentType, EmploymentType.SIMPLE_EMPLOYMENT) &&
                employee.status === getEnumKeyByValue(StatusType, StatusType.ACTIVE) &&
                user !== undefined &&
                (user.hasRole(Role.BR_HR) || user.hasRole(Role.BR_HR_HELYETTES) || user.hasRole(Role.VEZENYLO) || user.hasRole(Role.ADMIN)) && (
                    <>
                        <CornerButton px={0.25} py={0} onClick={() => onNotifyEmployee(employee)}>
                            <BusinessOutlinedIcon
                                sx={{
                                    padding: 0,
                                    margin: 0,
                                    fill: 'white',
                                }}
                            />
                        </CornerButton>

                        <Checkbox
                            sx={{
                                padding: 0,
                                margin: 0,
                                position: 'absolute',
                                right: 30,
                                bottom: 0,
                                color: 'rgb(255, 165, 0)',
                                '&.Mui-checked': {
                                    color: 'rgb(255, 165, 0)',
                                },
                            }}
                            checked={checkedEmployees.some(e => e.uuid === employee.uuid)}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={e => {
                                onEmployeeChange(employee, e.target.checked);
                            }}
                        />
                    </>
                )}
        </StyledEmployeeCard>
    );
};

export { EmployeeCard };
