export const AppColors = {
    darkBlue : '#1C255B',
    green : '#41BCA7',
    lightPurple : '#8D77E7',
    darkPurple : '#191654',
    lightBlue : '#26D7FE',
    deepdarkBlue : '#0185FF',
    dangerOrange : '#F89353',
    red : '#FF0000',
    grey: '#999999',
    warning: '#ed6c02',
}