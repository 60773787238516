import React, { createContext, useContext, useState } from 'react';

const EmployeeListSearchParamsContext = createContext({
    employeeListSearch: "",
    setEmployeeListSearchParams: (search: string) => {},
});

export const useEmployeeListSearchParams = () => useContext(EmployeeListSearchParamsContext);

export const EmployeeListSearchParamsProvider = ({ children }: { children: React.ReactNode }) => {
  const [employeeListSearch, setEmployeeListSearchParams] = useState<string>("");

  return (
      <EmployeeListSearchParamsContext.Provider value={{ employeeListSearch, setEmployeeListSearchParams }}>
          {children}
      </EmployeeListSearchParamsContext.Provider>
  );
};
