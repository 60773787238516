import React from 'react';
import {Box, List, ListItem, Paper, Typography} from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';
import {AppColors} from '../../../style/AppColors';

const ColorMarkupHelper = () => {
    return <Box>
        <Paper sx={{p: 1, fontSize: 14}}>
            <Typography variant={"h6"} p={0} m={0}>Súgó</Typography>
            <List>
                <ListItem sx={{p: 0}}>
                    <CircleIcon sx={{fontSize: 15, fill: AppColors.green, pr: 1}}/> Állományos munkavállaló
                </ListItem>
                <ListItem sx={{p: 0}}>
                    <CircleIcon sx={{fontSize: 15, fill: AppColors.lightBlue, pr: 1}}/> Alkalmi munkavállaló
                </ListItem>
                <ListItem sx={{p: 0}}>
                    <CircleIcon sx={{fontSize: 15, fill: AppColors.lightPurple, pr: 1}}/> Nyugdíjas munkavállaló
                </ListItem>
                <ListItem sx={{p: 0, justifyContent: "end"}}>
                    Hamarosan lejáró okmányok <CircleIcon sx={{fontSize: 15, fill: AppColors.dangerOrange, pl: 1}}/>
                </ListItem>
                <ListItem sx={{p: 0, justifyContent: "end"}}>
                    Nyugdíjba menő munkavállaló <CircleIcon sx={{fontSize: 15, fill: AppColors.darkPurple, pl: 1}}/>
                </ListItem>
                <ListItem sx={{p: 0}}>
                    <CircleIcon sx={{fontSize: 15, fill: AppColors.red, pr: 1}}/> Lejárt okmányok
                </ListItem>
                <ListItem sx={{p: 0}}>
                    <CircleIcon sx={{fontSize: 15, fill: AppColors.grey, pr: 1}}/> Inaktív munkavállaló
                </ListItem>
            </List>

        </Paper>
    </Box>
}

export {ColorMarkupHelper}