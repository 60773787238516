import React, { createContext, useContext, useState } from 'react';
import { ExpandedRowsType, ExpandedRows } from '../@types/index';

const ExpandedRowsContext = createContext<ExpandedRowsType>({
  expandedRows: {},
  setExpandedRows: (idMap: ExpandedRows) => {},
});

export const useExpandedRows = () => useContext(ExpandedRowsContext);

export const ExpandedRowsProvider = ({ children }: { children: React.ReactNode }) => {
  const [expandedRows, setExpandedRows] = useState<ExpandedRows>({});

  return (
    <ExpandedRowsContext.Provider value={{ expandedRows, setExpandedRows }}>
      {children}
    </ExpandedRowsContext.Provider>
  );
};